import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "components/layout"
import PropTypes from "prop-types"
import * as styles from "styles/tag_list.module.css"
import Meta from "components/meta"

const tag_list = ({
  data: {
    allMarkdownRemark: {
      group
    },
    site: {
      siteMetadata:{
         title ,
        },
    },
  },
}) => {
  return (
  <Layout>
    <Meta
    title="WORKS タグ一覧"
    />

    <h2>Tag List</h2>
    <ul className={styles.list}>

  {group.map(tag => (
    <li key={tag.fieldValue} className={styles.item}>
      <Link to={`/works/tag/${tag.fieldValue}` } >{tag.fieldValue}({tag.totalCount})</Link>
      </li>
    ))}

    </ul>
    </Layout>
  )
}

 
    


  
  export default tag_list;

export const pageQuery = graphql`
  query {
    site{
      siteMetadata{
        title
      }
    }
    allMarkdownRemark {
      group(field: frontmatter___tag) {
        fieldValue
        totalCount
      }


 
    }}
`